<template>
  <div style="margin-top: 2rem">
    <Toast position="top-right" :baseZIndex="99" />
    <div
      class="card card-custom gutter-b card-collapsed"
      data-card="false"
      id="cardFiltrosAgenda"
    >
      <div class="card-header" @click="cardFiltrosAgenda.toggle()">
        <div class="card-title mb-0">
          <h5 class="card-label mb-0">Filtros</h5>
        </div>
        <div class="card-toolbar">
          <a
            class="btn btn-icon btn-sm btn-light-primary mr-1 card-button-collapse"
          >
            <i class="ki ki-arrow-up icon-nm"></i>
          </a>

          <a
            class="btn btn-sm btn-light-warning mr-1 font-weight-bold"
            @click.stop="buscarEventos(undefined)"
          >
            Filtrar
          </a>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-5">
            <b-form-group>
              <label>Regras</label>
              <pv-multiselect
                class="form-control"
                emptyFilterMessage="Não existe regras cadastradas"
                filter
                optionLabel="mar_nome"
                :options="regras"
                placeholder="Todas"
                v-model="filtroRegraSelecionada"
                optionValue="modelo_agenda_regra_id"
              >
                <template #option="slotProps">
                  <div class="d-flex align-items-center">
                    <div
                      class="ml-1"
                      :style="`border-radius: 50%; width: 10px; height: 10px; background-color: ${slotProps.option.mar_cor};`"
                    />
                    <span class="ml-3">{{ slotProps.option.mar_nome }}</span>
                  </div>
                </template>
              </pv-multiselect>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group>
              <label>Status</label>
              <pv-multiselect
                class="form-control"
                emptyFilterMessage="Não existe regras cadastradas"
                filter
                optionLabel="acs_status"
                optionValue="agenda_calendario_status_id"
                :options="filtroStatus"
                placeholder="Todos"
                v-model="filtroStatusSelecionado"
              />
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group>
              <label>Sincronia</label>
              <pv-multiselect
                class="form-control"
                emptyFilterMessage="Não existe sincronia cadastradas"
                filter
                optionLabel="descricao"
                optionValue="codigo"
                :options="filtroSincronia"
                placeholder="Todos"
                v-model="filtroSincroniaSelecionado"
              />
            </b-form-group>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom mt-4">
      <div class="card-header">
        <div class="card-title">
          <h5 class="card-label">Calendário</h5>
        </div>
        <div class="card-toolbar">
          <!-- ROTINA DE SINCRONIZAÇÃO - ABORTADA POR HORA -->
          <!-- <button
            type="button"
            class="btn btn-success font-weight-bold mr-2"
            @click="sincronizarEventos()"
          >
            <i class="fa fa-arrows-rotate"></i> Sincronizar
          </button> -->
          <!-- <button
            type="button"
            class="btn btn-primary font-weight-bold"
            @click="formularioEvento()"
          >
            <i class="fa fa-plus"></i> Adicionar Evento
          </button> -->
        </div>
      </div>
      <div class="card-body">
        <b-overlay :show="carregandoAgenda" rounded="sm">
          <FullCalendar
            id="calendar-container"
            style="height: 300px"
            :options="calendarOptions"
            ref="calendario"
            @eventRender="showPopup"
          >
          </FullCalendar>
        </b-overlay>

        <div class="p-2 bg-gray-200 mt-4 p-4 rounded">
          <p class="font-weight-bolder">Legenda:</p>
          <div class="row">
            <div class="col-2">
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/check.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Aprovado</span>
              </div>
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/check-sync.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Aprovação pendente</span>
              </div>
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/check-erro.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Aprovado com erro</span>
              </div>
            </div>
            <div class="col-2">
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/x.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Cancelado</span>
              </div>
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/x-sync.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Cancelamento pendente</span>
              </div>
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/x-erro.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Cancelamento com erro</span>
              </div>
            </div>
            <div class="col-2">
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/new.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Novo Agendamento</span>
              </div>
              <div class="d-flex align-items-center">
                <img
                  src="../../../../../assets/icons/refused.svg"
                  style="width: 32px; height: 32px"
                />
                <span class="ml-3">Recusado</span>
              </div>
              <div
                v-if="agendaExibicao == 'timeDezDias'"
                class="d-flex align-items-center"
              >
                <img
                  src="../../../../../assets/icons/paperclip.svg"
                  style="
                    width: 23px;
                    height: 23px;
                    color: #808080;
                    margin-left: -8px;
                  "
                  class="m-2"
                />
                <span class="ml-2">Anexo</span>
              </div>
            </div>
            <div class="col"></div>
          </div>
        </div>
      </div>
    </div>

    <modal-evento
      ref="mdlEvento"
      @eventoCadastrado="adicionarEvento"
      @atualizar="buscarEventos(null, false)"
      @toast="addToast"
      :regras="regras"
    ></modal-evento>

    <modal-detalhes-evento
      ref="mdlDetalheEvento"
      :evento="eventoSelecionado"
      @editarEvento="editarEvento"
      @atualizar="buscarEventos(null, false)"
      @eventoExcluido="removerEvento"
      @eventoAtualizado="buscarEventos"
      @toast="addToast"
      :conta="conta"
    ></modal-detalhes-evento>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import FullCalendar from "@fullcalendar/vue";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import ptBrLocale from "@fullcalendar/core/locales/pt-br";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import moment from "moment";

import ModalEvento from "./ModalEvento";
import ModalDetalhesEvento from "./ModalDetalhesEvento";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";
import config from "@/core/config/layout.config.json";
import KTCard from "@/assets/js/components/card.js";

import Toast from "primevue/toast";

export default {
  name: "agenda",

  components: {
    FullCalendar,
    ModalEvento,
    ModalDetalhesEvento,
    Toast
  },

  data() {
    return {
      calendarOptions: {
        locale: ptBrLocale,
        plugins: [
          interactionPlugin,
          bootstrapPlugin,
          timeGridPlugin,
          listPlugin
          // DayGridPlugin
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridWeek,timeGridDay,timeDezDias"
        },
        views: {
          timeDezDias: {
            type: "list",
            duration: { days: 10 },
            buttonText: "Lista"
          }
        },
        height: 800,
        navLinks: true,
        eventLimit: true,
        themeSystem: "bootstrap",
        initialView: "timeDezDias",
        selectMirror: true,
        dayMaxEvents: true,
        slotMinTime: "05:00:00",
        slotMaxTime: "21:00:00",
        weekends: true,
        events: this.eventos,
        nowIndicator: true,
        eventClick: this.detalheEvento,
        datesSet: this.buscarEventos,
        eventDidMount: this.showPopup,
        eventContent: (eventInfo) => {
          this.$nextTick(() => {
            this.agendaExibicao = eventInfo.view.type;
            this.$forceUpdate();
          });
          let jsonData = JSON.parse(
            eventInfo.event.extendedProps.agc_json_data
          );
          return {
            html: `
            ${
              this.agendaExibicao != "timeDezDias"
                ? `
                <div class="fc-event-main-frame">

              <div class="fc-event-title-container">
                <div class="fc-event-title fc-sticky d-flex">
                  <i class="mr-2 icon icon-size-m ${this.pegarIcone(
                    eventInfo.event.extendedProps.agenda_calendario_status_id,
                    eventInfo.event.extendedProps.agc_sincronizado
                  )}
                " style="margin-top: -4px"></i>
                  ${eventInfo.event.title}
                </div>
              </div>
              </div>
              `
                : `
                <td class="fc-list-event-title d-flex">
                  ${
                    ["AP", "CA"].includes(
                      eventInfo.event.extendedProps.agenda_calendario_status_id
                    ) &&
                    ["E"].includes(
                      eventInfo.event.extendedProps.agc_sincronizado
                    )
                      ? "<i>❗</i>"
                      : `<i class="mr-2 icon icon-size-m ${this.pegarIcone(
                          eventInfo.event.extendedProps
                            .agenda_calendario_status_id,
                          eventInfo.event.extendedProps.agc_sincronizado,
                          true
                        )}"></i>`
                  }
                  
                  
                  ${
                    Object.keys(eventInfo.event.extendedProps.arquivos).length >
                    0
                      ? '<i class="mr-2 paperclip"></i>'
                      : ""
                  }
                  <a tabindex="0">
                    ${
                      jsonData.prestador_id_txt
                        ? `<b>PRESTADOR</b>: ${jsonData.prestador_id_txt} |`
                        : ``
                    }

                    ${
                      jsonData.exame_id_txt
                        ? ` <b>EXAME</b>: ${jsonData.exame_id_txt} |`
                        : ``
                    }
                    ${
                      jsonData.cliente_id_txt
                        ? ` <b>CLIENTE:</b> ${jsonData.cliente_id_txt}`
                        : ``
                    }
                  </a>
                </td>
                `
            }
              
             `
          };
        }
      },
      eventos: [],
      eventoSelecionado: {},
      eventoPopup: {},
      status: [],
      agendaExibicao: "timeDezDias",
      iniciarAtualizacaoEventos: true,
      intervaloAtualizar: null,
      filtroRegraSelecionada: [],
      filtroRegras: [],
      filtroStatusSelecionado: [],
      filtroSincroniaSelecionado: [],
      filtroSincronia: [],
      filtroStatus: [],
      cardFiltrosAgenda: null,
      regras: [],
      conta: null,
      carregandoAgenda: false,
      buscando: false
    };
  },

  mounted() {
    let newConfig = JSON.parse(JSON.stringify(config));
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Agende" },
      {
        title: "Calendário",
        route: "/painel/agende/calendario"
      }
    ]);
    this.$store.dispatch(ADD_BODY_CLASSNAME, "aside-minimize");
    newConfig.header.self.display = false;
    newConfig.subheader.display = false;
    newConfig.styleContetnt = { padding: "0" };
    newConfig.styleContainer = { "max-width": "100%", margin: 0 };
    newConfig.wrapper = { "padding-top": 0 };
    newConfig.scrollTop.self.display = false;
    newConfig.aside.self.minimize.hoverable = true;
    this.$store.dispatch(SET_LAYOUT_CONFIG, newConfig);
    moment.locale("pt-br");

    this.cardFiltrosAgenda = new KTCard("cardFiltrosAgenda");

    this.carregarRegras();
    this.carregarConta();
    setTimeout(() => {
      this.cardFiltrosAgenda.toggle();
    }, 100);

    setTimeout(() => {
      this.solicitarNotificacaoNavegador();
    }, 10000);
  },

  beforeDestroy() {
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "aside-minimize");
  },

  methods: {
    adicionarEvento(evento) {
      const calendarApi = this.$refs.calendario.getApi();

      this.removerEvento(evento.agenda_calendario_id);
      const eventoCopia = { ...evento };
      calendarApi.addEvent({
        id: eventoCopia.agenda_calendario_id,
        title: `${eventoCopia.arquivos.length != 0 ? "📎 " : ""}${
          eventoCopia.agc_nome
        }`,
        start: `${eventoCopia.agc_data_inicio} ${eventoCopia.agc_hora_inicio}`,
        end: `${eventoCopia.agc_data_fim} ${eventoCopia.agc_hora_fim}`,
        allDay: false,
        extendedProps: eventoCopia,
        color: eventoCopia.mar_cor
      });
    },

    formularioEvento(evento) {
      this.$refs.mdlEvento.abrir({ ...evento });
    },

    detalheEvento(info) {
      this.eventoSelecionado = {};
      this.eventoSelecionado = { ...info.event.extendedProps };
      this.$refs.mdlDetalheEvento.abrir(this.eventoSelecionado);
    },

    carregarConta() {
      ApiService.get("administrativo/conta/pegar").then((response) => {
        this.conta = response.data.conta;
      });
    },

    editarEvento(evento) {
      this.formularioEvento(evento ? evento : { ...this.eventoSelecionado });
      this.eventoSelecionado = {};
    },

    removerEvento(id) {
      const calendarApi = this.$refs.calendario?.getApi();
      const evento = calendarApi.getEventById(id);
      if (evento) {
        evento.remove();
      }
    },

    buscarEventos(dateInfo, showOverlay = true) {
      const calendario = this.$refs.calendario;
      const calendarApi = calendario.getApi();

      if (!dateInfo) {
        dateInfo = {
          startStr: calendarApi.formatIso(
            calendarApi.currentData.dateProfile.currentRange.start
          ),
          endStr: calendarApi.formatIso(
            calendarApi.currentData.dateProfile.currentRange.end
          )
        };
      }
      if (showOverlay) {
        this.carregandoAgenda = true;
      }
      this.buscando = true;
      ApiService.post(
        "agenda/calendario/listar",
        {
          dataHoraInicio: dateInfo.startStr,
          dataHoraFim: dateInfo.endStr,
          modelo_agenda_regra_id: !this.filtroRegraSelecionada.length
            ? null
            : this.filtroRegraSelecionada,
          agenda_calendario_status_id: !this.filtroStatusSelecionado.length
            ? null
            : this.filtroStatusSelecionado,
          agc_sincronizado: !this.filtroSincroniaSelecionado.length
            ? null
            : this.filtroSincroniaSelecionado
        },
        null,
        showOverlay
      )
        .then((response) => {
          const eventos = response.data.data.eventos;
          this.status = response.data.data.status;

          /* Remove os eventos antigos do calendário */
          const todosEventos = calendarApi.getEvents();
          todosEventos.forEach((event) => {
            event.remove();
          });

          /* Adiciona os novos eventos ao calendário */
          eventos.forEach((evento) => {
            this.adicionarEvento(evento);
          });

          if (this.iniciarAtualizacaoEventos) {
            this.iniciarAtualizacaoEventos = false;
            this.iniciarAtualizacao();
          }

          this.carregandoAgenda = false;
        })
        .finally(() => {
          this.buscando = false;
          this.carregandoAgenda = false;
        });
    },

    pegarIcone(status, sincronizado, naLista = false) {
      switch (status) {
        case "AP":
          if (sincronizado == "S") {
            return "check";
          }
          if (sincronizado == "P") {
            return "check-sync";
          }
          if (sincronizado == "E") {
            if (naLista) return "x";
            return "check-erro";
          }
          break;
        case "CA":
          if (sincronizado == "S") {
            return "x";
          }
          if (sincronizado == "P") {
            return "x-sync";
          }
          if (sincronizado == "E") {
            return "x-erro";
          }
          break;
        case "RE":
          return "refused";
        case "NO":
          return "new";

        default:
          break;
      }
    },

    addToast(data) {
      this.$toast.add(data);
    },

    formatarDataString(dataHora, format) {
      return moment(dataHora).format(format);
    },

    async carregarRegras() {
      let response = await ApiService.get("agenda/calendario/listar/filtros");
      const visited_rules = {};
      this.regras = Object.values(response.data.data.modelosAgendaRegra).map(
        (regra) => {
          if (visited_rules[regra.mar_nome] > 0) {
            regra.mar_nome =
              regra.mar_nome + " (" + visited_rules[regra.mar_nome] + ")";
          }
          visited_rules[regra.mar_nome] =
            (visited_rules[regra.mar_nome] || 0) + 1;
          return regra;
        }
      );

      this.filtroStatus = Object.values(response.data.data.status);
      this.filtroSincronia = Object.values(response.data.data.statusSincronia);
    },

    showPopup(info) {
      this.eventoPopup = { ...info.event.extendedProps };
      const content = document.createElement("div");
      content.innerHTML = `
          <div class="p-2 w-100">
            <div class="d-flex justify-content-between mb-2"">
              <div class="d-flex justify-content-center align-items-center m-1">
                <div style="border-radius: 50%; width: 11px; height: 11px; background-color: ${
                  this.eventoPopup.mar_cor
                }"></div>
                <div class="ml-2 icon ${this.pegarIcone(
                  this.eventoPopup.agenda_calendario_status_id,
                  this.eventoPopup.agc_sincronizado
                )}" style="width: 26px; height: 26px" data-toggle="tooltip" data-placement="top" title="${
        this.eventoPopup.acs_status
      }"></div>
              </div>
              <div>
                ${
                  this.eventoPopup.agenda_calendario_status_id == "NOVO"
                    ? `<button type="button">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                  </svg>
                </button>`
                    : "<a></a>"
                }
                
              </div>
            </div>

            <div class="d-flex flex-column">
              <!-- NOME DO PACIENTE -->
              <div class="d-flex">
                <div class="d-flex align-items-center mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                    <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
                  </svg>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <span class="fc-title" style="font-size: 0.8rem">
                    ${this.eventoPopup.agc_nome}
                  </span>
                </div>
              </div>

              <!-- HORARIO DO AGENDAMENTO -->
              <div class="d-flex mt-3 ">
                <div class="d-flex align-items-center mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm" viewBox="0 0 16 16">
                    <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5z"/>
                    <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1h-3zm1.038 3.018a6.093 6.093 0 0 1 .924 0 6 6 0 1 1-.924 0zM0 3.5c0 .753.333 1.429.86 1.887A8.035 8.035 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5zM13.5 1c-.753 0-1.429.333-1.887.86a8.035 8.035 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1z"/>
                  </svg>
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <span style="font-size: 0.8rem">
                    ${this.formatarDataString(
                      this.eventoPopup.agc_data_inicio +
                        " " +
                        this.eventoPopup.agc_hora_inicio,
                      "DD/MM/YYYY HH:mm"
                    )}
                  </span>
                </div>
              </div>

              <!-- LOCALIZAÇÃO DO AGENDAMENTO, CASO EXISTA -->
              ${
                this.eventoPopup.agc_local
                  ? `
              <div class="d-flex mt-3">
                <div class="d-flex align-items-center mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  </svg>
                </div>
                <div class="d-flex justify-content-center">
                  <span style="font-size: 0.8rem">
                    ${this.eventoPopup.agc_local}
                  </span>
                </div>
              </div>
              `
                  : ""
              }

              <!-- DESCRIÇÃO, CASO EXISTA -->
              ${
                this.eventoPopup.agc_descricao
                  ? `
                <div class="p-2 mt-3" style="background-color: #EBEDF3; border-radius: 8px; font-size: 0.8rem; min-height: 80px"> 
                  ${this.eventoPopup.agc_descricao}
                </div>  
              `
                  : ""
              }
            </div>
          </div>
        `;
      const button = content.querySelector("button");
      if (button) {
        button.addEventListener("click", () => {
          this.editarEvento(this.eventoPopup);
          tip.hide();
        });
      }
      let tip = tippy(info.el, {
        content: content,
        placement: "top",
        animation: "scale",
        theme: "light",
        allowHTML: true,
        arrow: false,
        delay: [500, 100],
        interactive: true,
        appendTo: document.querySelector("#calendar-container")
      });

      // tippy.hideAll({ exclude: tip });
    },

    beforeDestroy() {
      clearInterval(this.iniciarAtualizacao);
    },

    iniciarAtualizacao() {
      const self = this;

      this.intervaloAtualizar = setInterval(function () {
        if (this.buscando) return;
        self.buscarEventos(undefined, false);
      }, 60000);
    },

    async solicitarNotificacaoNavegador() {
      const valor = await this.$notification.requestPermission();
      if (valor == "denied") {
        this.$toast.add({
          severity: "warn",
          summary: "Notificação de Agendamentos",
          detail:
            "Você ainda não permitiu as notificações de agendamento do nosso sistema. Ao fazê-lo, será informado sempre que uma nova notificação estiver disponível, garantindo que você não perca nenhuma atualização importante."
        });
      }
    }

    // ROTINA DE SINCRONIZAÇÃO - ABORTADA POR HORA
    // sincronizarEventos() {
    //   const calendarApi = this.$refs.calendario.getApi();
    //   const events = calendarApi.getEvents();
    //   events.forEach((event) => {
    //     event.remove();
    //   });

    //   ApiService.get("agenda/calendario/sincronizar-agenda-marcada").then(
    //     (response) => {
    //       const modelosAgenda = response.data.data;

    //       modelosAgenda.forEach((modeloAgenda) => {
    //         this.requisitarAgendaMarcada(modeloAgenda.id);
    //       });
    //     }
    //   );
    // },

    // requisitarAgendaMarcada(modeloAgendaRegraId) {
    //   ApiService.get(
    //     `agenda/calendario/requisitar-agenda-marcada/${modeloAgendaRegraId}`
    //   ).then((response) => {
    //     const requisicao = response.data.requisicao;

    //     this.respostaAgendaMarcada(modeloAgendaRegraId, requisicao.uid);
    //   });
    // },

    // respostaAgendaMarcada(modeloAgendaRegraId, uid) {
    //   ApiService.get(
    //     `agenda/calendario/resposta-agenda-marcada/${modeloAgendaRegraId}/${uid}`
    //   ).then((response) => {
    //     console.log(response.data);
    //   });
    // }
  }
};
</script>

<style scoped>
.icon {
  /* font-family: "Font Awesome 5 Free"; */
  /* content: "\f095"; */
  display: inline;
  background-color: white;
  /* border-radius: 10px; */
  /* width: 100%; */
  width: auto;
  /* padding-right: 3px;
  vertical-align: middle;
  font-weight: 900; */
}

.icon-size-p {
  width: 16px;
  height: 16px;
}

.icon-size-m {
  width: 18px;
  height: 18px;
}

.check {
  content: url(../../../../../assets/icons/check.svg);
}

.check-sync {
  content: url(../../../../../assets/icons/check-sync.svg);
}

.check-erro {
  content: url(../../../../../assets/icons/check-erro.svg);
}

.x {
  /* content: url(../../../../../assets/icons/x.svg); */
  content: url(../../../../../assets/icons/x.svg);
}

.x-sync {
  content: url(../../../../../assets/icons/x-sync.svg);
}

.x-erro {
  content: url(../../../../../assets/icons/x-erro.svg);
}

.new {
  content: url(../../../../../assets/icons/new.svg);
}

.refused {
  content: url(../../../../../assets/icons/refused.svg);
}

.paperclip {
  content: url(../../../../../assets/icons/paperclip.svg);
}

.fc-timegrid-col-events
  > .fc-timegrid-event-harness
  .fc-timegrid-event-harness-inset {
  z-index: none !important;
}

.fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
  z-index: none !important;
}
.tippy-box {
  z-index: 9999;
}
.ui-datepicker-trigger {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: transparent;
  border: none;
  margin: 0 !important;
  padding: 0 !important;
  height: 30px !important;
}
.ui-datepicker-trigger .input-group-addon:last-child {
  border-left: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc-basicDay-button {
  border-radius: 0;
}
body {
  margin: 40px 10px;
  padding: 0;
  font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif;
  font-size: 14px;
}
#calendar {
  max-width: 900px;
  margin: 0 auto;
}
.hoverEffect {
  font-size: 29px;
  position: absolute;
  margin: 30px 55px;
  cursor: pointer;
}

[type="date"] {
  background: #fff
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    97% 50% no-repeat;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */
body {
  padding: 4em;
  background: #e5e5e5;
  font: 13px/1.4 Geneva, "Lucida Sans", "Lucida Grande", "Lucida Sans Unicode",
    Verdana, sans-serif;
}
label {
  display: block;
}
input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
}

/* TESTE */
.ui-datepicker-trigger {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: transparent;
  border: none;
  margin: 0 !important;
  padding: 0 !important;
  height: 30px !important;
}
.ui-datepicker-trigger .input-group-addon:last-child {
  border-left: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.fc-basicDay-button {
  border-radius: 0;
}
body {
  margin: 40px 10px;
  padding: 0;
  font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif;
  font-size: 14px;
}
#calendar {
  max-width: 900px;
  margin: 0 auto;
}
.hoverEffect {
  font-size: 29px;
  position: absolute;
  margin: 30px 55px;
  cursor: pointer;
}
</style>
