<template>
  <b-modal
    title="Preview"
    ref="mdlPreview"
    :size="arquivo.tipo_original == 'document' ? 'xl' : 'lg'"
    centered
    hide-header
    ok-title="Baixar"
    cancel-title="Fechar"
    footer-class="justify-content-center p-2"
    button-size="sm"
    @ok.prevent="downloadArquivo"
  >
    <template v-if="arquivo.tipo_original == 'image'">
      <b-carousel>
        <b-carousel-slide :img-src="arquivo.caminho_s3"></b-carousel-slide>
      </b-carousel>
    </template>

    <template v-else>
      <b-embed type="iframe" :src="arquivo.caminho_s3"></b-embed>
    </template>
  </b-modal>
</template>

<script>
import ApiService from "@/core/services/api.service";
import download from "downloadjs";

export default {
  props: ["arquivo"],

  methods: {
    abrir() {
      this.$refs["mdlPreview"].show();
    },

    async downloadArquivo() {
      ApiService.post(
        "agenda/calendario/download-arquivo",
        {
          caminho: this.arquivo.caminho,
          nome: `${this.arquivo.descricao}.${this.arquivo.extensao}`
        },
        {
          responseType: "blob"
        }
      ).then((response) => {
        const content = response.headers["content-type"];
        download(
          response.data,
          `${this.arquivo.descricao}.${this.arquivo.extensao}`,
          content
        );
      });
    }
  }
};
</script>
