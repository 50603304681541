<template>
  <div>
    <b-modal
      ref="mdlEvento"
      id="mdlEvento"
      title="Evento"
      @hidden="limpar"
      @shown="validacao"
      :ok-disabled="this.filtrosDinamicosCarregando.length > 0"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmEvento">
        <b-form-group>
          <label>Nome <span class="text-danger">*</span>:</label>
          <b-form-input name="nome" v-model="frmEvento.nome"></b-form-input>
        </b-form-group>

        <b-form-group>
          <label>Descrição <span class="text-danger">*</span>:</label>
          <b-form-input
            name="descricao"
            v-model="frmEvento.descricao"
          ></b-form-input>
        </b-form-group>

        <b-form-group>
          <label>Regra <span class="text-danger">*</span>:</label>
          <b-form-select
            v-model="frmEvento.modeloAgendaRegraId"
            :options="regras"
            @change="mudancaRegra"
            name="regra"
            text-field="mar_nome"
            value-field="modelo_agenda_regra_id"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-for="filtro in filtrosDinamico" :key="filtro.filtro_id">
          <label>
            {{
              data.modeloAgendaFiltro
                .find((i) => i.filtro_id == filtro.filtro_id)
                .fil_msg_chatbot.replaceAll("*", "")
            }}
            <!-- {{
              filtro.fil_campo[0].toUpperCase() +
              filtro.fil_campo
                .substring(1)
                .replaceAll("_", " ")
                .replaceAll("id", "")
            }} -->
            <span class="text-danger">*</span>
          </label>
          <div class="d-flex align-items-center">
            <b-form-select
              v-model="data.agendaCalendario.agc_json_data[filtro.fil_campo]"
              :options="frmEvento.filtrosDinamico[filtro.fil_campo].opcoes"
              :name="'regra_' + filtro.fil_campo"
              @change="agendaLivre = null"
              text-field="value"
              value-field="id"
            ></b-form-select>
            <b-spinner
              v-if="
                filtrosDinamicosCarregando.some((i) => i == filtro.fil_campo)
              "
              class="ml-3"
              label="Spinning"
            ></b-spinner>
            <b-button
              v-if="botoesRecarregarFiltro.some((i) => i == filtro.fil_campo)"
              variant="outline-primary"
              class="ml-3"
              @click="
                recarregarFiltroDinamico(
                  data.modeloAgendaRegra.sistema_licenciada_id,
                  filtro,
                  data.modeloAgendaFiltro,
                  5
                )
              "
            >
              <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </b-form-group>

        <div class="d-flex flex-row-reverse w-100"></div>
        <div class="d-flex mt-4">
          <b-form-group class="w-100">
            <label
              >Data do agendamento <span class="text-danger">*</span>:</label
            >
            <div class="d-flex w-100">
              <b-form-datepicker
                v-model="frmEvento.dataEvento"
                class="mr-3 w-100"
                @input="atualizarHorarioDisponivel"
                :date-disabled-fn="dataIndisponivel"
                locale="pt"
              />
              <b-form-select
                class="ml-3 w-100"
                v-model="frmEvento.horaEvento"
                :options="horaDisponivel"
              />
              <b-button
                variant="outline-primary"
                :disabled="this.filtrosDinamicosCarregando.length > 0"
                class="ml-3"
                style="width: 330px"
                @click="
                  consultarAgendaDisponivel(
                    frmEvento.modeloAgendaRegraId,
                    data.agendaCalendario.agc_json_data
                  )
                "
                >Atualizar agenda</b-button
              >
            </div>
          </b-form-group>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import moment from "moment";
import Dropdown from "primevue/dropdown";

export default {
  props: ["regras"],
  comments: {
    Dropdown
  },
  data() {
    return {
      frmEvento: {
        id: null,
        nome: "",
        descricao: "",
        local: "",
        dataInicio: "",
        horaInicio: "",
        dataFim: "",
        horaFim: "",
        dataEvento: null,
        horaEvento: null,
        modeloAgendaRegraId: {}
      },
      data: [],
      filtrosDinamico: [],
      filtrosDinamicosCarregando: [],
      botoesRecarregarFiltro: [],
      agendaLivre: null,
      dataDisponivel: [],
      horaDisponivel: []
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"])
  },
  methods: {
    limpar() {
      this.frmEvento.id = null;
      this.frmEvento.nome = "";
      this.frmEvento.descricao = "";
      this.frmEvento.local = "";
      this.frmEvento.dataInicio = moment().format("YYYY-MM-DD");
      this.frmEvento.horaInicio = moment().format("HH:mm");
      this.frmEvento.dataFim = moment().format("YYYY-MM-DD");
      this.frmEvento.horaFim = moment().endOf("hour").format("HH:mm");
      this.frmEvento.modeloAgendaRegraId = [];
      this.frmEvento.filtrosDinamico = {};
      this.data = {};
    },

    abrir(evento) {
      this.limpar();
      if (evento) {
        let dataHora = moment(
          `${evento.agc_data_inicio} ${evento.agc_hora_inicio}`
        );

        this.frmEvento.id = evento.agenda_calendario_id;
        this.frmEvento.nome = evento.agc_nome;
        this.frmEvento.modeloAgendaRegraId = evento.modelo_agenda_regra_id;
        this.frmEvento.descricao = evento.agc_descricao;
        this.frmEvento.local = evento.agc_local;
        this.frmEvento.dataInicio = evento.agc_data_inicio;
        this.frmEvento.horaInicio = evento.agc_hora_inicio;
        this.frmEvento.dataFim = evento.agc_data_fim;
        this.frmEvento.horaFim = evento.agc_hora_fim;
        this.frmEvento.filtrosDinamico = {};

        this.frmEvento.dataEvento = dataHora.format("YYYY-MM-DD");
        this.frmEvento.horaEvento = dataHora.format("HH:mm");
        this.horaDisponivel = [dataHora.format("HH:mm")];

        // this.data = {};
      }
      this.carregarAgenda(evento.agenda_calendario_id);

      this.$refs["mdlEvento"].show();
    },

    async carregarAgenda(id) {
      let response = await ApiService.get(`agenda/calendario/editar/${id}`);
      if (response) {
        this.data = response.data;
        this.data.agendaCalendario.agc_json_data = JSON.parse(
          this.data.agendaCalendario.agc_json_data
        );

        this.data.agendaCalendario.agc_json_data_conv = this.converterObjeto(
          this.data.agendaCalendario.agc_json_data
        );

        let temp = Object.values(this.data.filtrosDinamico);
        temp.forEach((filtro) => {
          this.frmEvento.filtrosDinamico[filtro.fil_campo] = {
            selecionado: this.data.agendaCalendario.agc_json_data
              ? this.data.agendaCalendario.agc_json_data[filtro.fil_campo]
              : null,
            opcoes: []
          };
        });
        this.filtrosDinamico = temp;
        this.carregarFiltroDinamico(
          this.filtrosDinamico,
          this.data.modeloAgendaFiltro,
          this.data.modeloAgendaRegra.sistema_licenciada_id
        );
      }
    },

    dataIndisponivel(ymd, date) {
      if (this.agendaLivre) {
        let datasDisponiveis = Object.keys(this.agendaLivre);

        let dias = datasDisponiveis.map((i) => {
          return moment(i).format("YYYY-MM-DD");
        });
        let valor = dias.some((i) => i == moment(date).format("YYYY-MM-DD"));
        return !valor;
      }
      if (!this.agendaLivre) {
        const v =
          moment(date).format("YYYY-MM-DD") !==
          moment(this.frmEvento.dataEvento).format("YYYY-MM-DD");
        return v;
      }
    },

    atualizarHorarioDisponivel(event) {
      this.horaDisponivel = this.agendaLivre[event].map((i) =>
        moment(i).format("HH:mm")
      );
      this.frmEvento.horaEvento = null;
    },

    async carregarFiltroDinamico(
      filtroDinamico,
      agendaFiltro,
      sistemaLicenciada
    ) {
      filtroDinamico.forEach((filtro) => {
        this.$nextTick(() => {
          this.filtrosDinamicosCarregando.splice(
            this.filtrosDinamicosCarregando.length,
            0,
            filtro.fil_campo
          );
        });

        this.pegarRespostaFiltroDinamico(
          sistemaLicenciada,
          filtro,
          agendaFiltro,
          filtro.requisicao.uid,
          5
        );
      });
    },

    pegarRespostaFiltroDinamico(
      sistemaLicenciada,
      filtro,
      agendaFiltro,
      uid,
      tentativa
    ) {
      ApiService.get(
        `administrativo/campanha/resposta-filtro-consulta/${sistemaLicenciada}/${uid}`,
        "",
        false
      )
        .then(async (response) => {
          if (response.data.requisicao.status == "PENDENTE") {
            await new Promise((r) => setTimeout(r, 1000));
            if (tentativa <= 0) {
              let index = this.filtrosDinamicosCarregando.indexOf(
                filtro.fil_campo
              );
              this.filtrosDinamicosCarregando.splice(index, 1);
              this.$emit("toast", {
                severity: "error",
                summary: "Erro ao carregar os filtros",
                detail:
                  "Houve um problema ao tentar carregar os filtros dinâmicos. Tente novamente mais tarde!.",
                life: 7000
              });
              this.$forceUpdate();

              //Adiciona botões para realizar a nova tentativa de recarregar o filtro dinamico
              this.botoesRecarregarFiltro.splice(
                this.botoesRecarregarFiltro.length,
                0,
                filtro.fil_campo
              );
              return;
            }
            this.pegarRespostaFiltroDinamico(
              sistemaLicenciada,
              filtro,
              agendaFiltro,
              uid,
              --tentativa
            );
            return;
          }
          let opcoes = agendaFiltro
            .filter((i) => i.filtro_id == filtro.filtro_id)
            .map((i) => i.maf_valor.trim());

          let resultado = response.data.requisicao.requisicao.resultado;

          let select = resultado.filter((i) =>
            opcoes.includes(i.id.toString().trim())
          );

          this.frmEvento.filtrosDinamico[filtro.fil_campo].opcoes = select;

          let index = this.filtrosDinamicosCarregando.indexOf(filtro.fil_campo);
          this.filtrosDinamicosCarregando.splice(index, 1);
          this.$forceUpdate();
        })
        .catch((erro) => {
          let index = this.filtrosDinamicosCarregando.indexOf(filtro.fil_campo);
          this.filtrosDinamicosCarregando.splice(index, 1);
          this.$forceUpdate();
        });
    },

    async recarregarFiltroDinamico(
      sistemaLicenciada,
      filtro,
      agendaFiltro,
      tentativa
    ) {
      // Remover o botão de recarregamento da tela
      let index = this.botoesRecarregarFiltro.indexOf(filtro.fil_campo);
      this.botoesRecarregarFiltro.splice(index, 1);

      // Adiciona na lista o filtro que está carregando
      this.filtrosDinamicosCarregando.splice(
        this.filtrosDinamicosCarregando.length,
        0,
        filtro.fil_campo
      );

      const { data } = await ApiService.get(
        `administrativo/campanha/filtro-consulta/${sistemaLicenciada}/${filtro.filtro_id}`,
        "",
        false
      );
      this.pegarRespostaFiltroDinamico(
        sistemaLicenciada,
        filtro,
        agendaFiltro,
        data.requisicao.uid,
        tentativa
      );
    },

    mudancaRegra() {
      this.filtrosDinamico = [];
      ApiService.get(
        `agenda/calendario/modelo-agenda-regra/${this.frmEvento.modeloAgendaRegraId}`
      ).then((response) => {
        let temp = Object.values(response.data.filtrosDinamico);
        this.data.filtrosDinamico = this.filtrosDinamico;
        this.data.modeloAgendaFiltro = response.data.modeloAgendaFiltro;
        this.data.modeloAgendaRegra = response.data.modeloAgendaRegra;

        temp.forEach((filtro) => {
          this.frmEvento.filtrosDinamico[filtro.fil_campo] = {
            selecionado: this.data.agendaCalendario.agc_json_data
              ? this.data.agendaCalendario.agc_json_data[filtro.fil_campo]
              : null,
            opcoes: []
          };
        });

        this.filtrosDinamico = temp;
        this.carregarFiltroDinamico(
          this.filtrosDinamico,
          this.data.modeloAgendaFiltro,
          this.data.modeloAgendaRegra.sistema_licenciada_id
        );
      });
    },

    async consultarAgendaDisponivel(modeloAgendaRegraId, parametros) {
      ApiService.post(
        `agenda/calendario/consultar-agenda`,
        {
          modelo_agenda_regra_id: modeloAgendaRegraId,
          parametros
        },
        null,
        true
      )
        .then((response) => {
          let { data } = response;
          ApiService.post(
            `agenda/calendario/resposta-consultar-agenda`,
            {
              modelo_agenda_regra_id: modeloAgendaRegraId,
              uid: data.requisicao.uid
            },
            null,
            true
          )
            .then((resp) => {
              this.agendaLivre = resp.data.agendaLivre;
              if (resp.data.sucesso && resp.data.sucesso === false) {
                this.$emit("toast", {
                  severity: "error",
                  summary: "Erro ao consultar agenda disponível",
                  detail:
                    "Houve um problema ao tentar exibir os dias e horários disponíveis. Por favor, tente novamente. Se o problema persistir, por favor, entre em contato conosco.",
                  life: 7000
                });
              }
            })
            .catch((erro) => {
              this.$emit("toast", {
                severity: "error",
                summary: "Erro ao consultar agenda disponível",
                detail:
                  "Houve um problema ao tentar exibir os dias e horários disponíveis. Por favor, tente novamente. Se o problema persistir, por favor, entre em contato conosco.",
                life: 7000
              });
            });
        })
        .catch();
    },
    converterObjeto(array) {
      let obj = {};
      for (const key in this.data.agendaCalendario.agc_json_data) {
        if (key.endsWith("_id")) {
          obj[array[key + "_txt"]] = array[key];
        }
      }
      return obj;
    },

    validacao() {
      const frmEvento = this.$refs["frmEvento"];

      this.fv = formValidation(frmEvento, {
        fields: {
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          modeloAgendaRegraId: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          descricao: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          local: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          dataInicio: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          horaInicio: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          dataFim: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          horaFim: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    gerarJsonData() {
      let temp = this.data.agendaCalendario.agc_json_data;
      let agc_json_data = {
        cliente_id: temp.cliente_id,
        cliente_id_txt: temp.cliente_id_txt,
        cliente_nome: temp.cliente_nome
      };
      this.filtrosDinamico.forEach((filtro) => {
        let key = filtro.fil_campo;
        if (key.endsWith("_id")) {
          agc_json_data[key] =
            this.data.agendaCalendario.agc_json_data[filtro.fil_campo];
          agc_json_data[key + "_txt"] = this.frmEvento.filtrosDinamico[
            filtro.fil_campo
          ].opcoes.find((i) => i.id == agc_json_data[key]).value;
        }
      });
      return agc_json_data;
    },

    salvar(e) {
      e.preventDefault();

      let agc_json_data = this.gerarJsonData();

      let dataFinal = moment(
        `${this.frmEvento.dataEvento} ${this.frmEvento.horaEvento}`
      ).add(this.data.modeloAgendaRegra.mar_tempo_atendimento, "minutes");

      let obj = {
        agenda_calendario_id: this.data.agendaCalendario.agenda_calendario_id,
        agc_nome: this.frmEvento.nome,
        agc_descricao: this.frmEvento.descricao,
        agc_local: this.data.agendaCalendario.agc_local,
        agc_data_inicio: this.frmEvento.dataEvento,
        agc_hora_inicio: this.frmEvento.horaEvento,
        agc_data_fim: dataFinal.format("YYYY-MM-DD"),
        agc_hora_fim: dataFinal.format("HH:mm"),
        agc_json_data: JSON.stringify(agc_json_data),
        modelo_agenda_regra_id:
          this.data.agendaCalendario.modelo_agenda_regra_id,
        agenda_calendario_status_id:
          this.data.agendaCalendario.agenda_calendario_status_id,
        agc_identificado: true
      };

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("agenda/calendario/salvar", obj)
            .then((response) => {
              const evento = response.data.data;
              this.$emit("eventoCadastrado", evento);
              this.$refs["mdlEvento"].hide();

              Swal.fire({
                title: "MENSAGEM",
                text: "Evento salvo com sucesso!",
                icon: "success",
                heightAuto: false
              });
              this.$emit("atualizar");
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },
    getFiltrosDinamico(fil_campo) {
      try {
        let ref = this.frmEvento.filtrosDinamico[fil_campo];
        if (ref) {
          return ref;
        } else {
          this.frmEvento.filtrosDinamico[fil_campo] = {
            selecionado: null,
            opcoes: []
          };
        }
      } catch (error) {
        Object.defineProperty(this.frmEvento.filtrosDinamico, fil_campo, {
          selecionado: null,
          opcoes: []
        });
      }
      return this.frmEvento.filtrosDinamico[fil_campo];
    },
    mostrarSpinner(filtro) {
      let valor = this.filtrosDinamicosCarregando.find(
        (i) => i.fil_campo == filtro.fil_campo
      );
      return valor != undefined;
    }
  }
};
</script>
